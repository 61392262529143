import { Box, Divider, Typography } from '@mui/material';
import "swagger-ui-react/swagger-ui.css";

const OpenApi = () => {
    return (
        <>
            <Box sx={{ flexDirection: 'row', padding: '2rem' }}>
                <Typography variant='h4'>The TUS API</Typography>
                <Typography>
                    The Uniform Solution exposes APIs that conform to the
                    OpenAPI standard. Choose from the APIs listed in the navigation bar.


                </Typography>
                <Typography>
                <br />Diamond Data Systems<br />
                    3209 Talon Drive<br />
                    Richardson, TX 75082
                </Typography>
            </Box>
        </>
    );
}

export default OpenApi;