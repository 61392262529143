import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";


const Order = () => {
    return (
        <SwaggerUI
            url="/openapi-json/order.json"
            docExpansion={"full"}
        />
    );
}

export default Order;