import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link, Outlet } from 'react-router-dom';


function MainLayout() {
    return (
        <>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                        </Typography>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                mr: 2,
                                display: { xs: 'flex' },
                                flexGrow: 1,
                                fontWeight: 500,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            TUS APIs
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
                            <Button
                                component={Link}
                                to="/"
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Home
                            </Button>
                            <Button
                                component={Link}
                                to="/customer"
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Customer
                            </Button>
                            <Button
                                component={Link}
                                to="/order"
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Order
                            </Button>
                            <Button
                                component={Link}
                                to="/product"
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Product
                            </Button>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Outlet />
        </>
    );
};

export default MainLayout;