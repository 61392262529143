import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";


const Product = () => {
    return (
        <SwaggerUI
            url="/openapi-json/product.json"
            docExpansion={"full"}
        />
    );
}

export default Product;