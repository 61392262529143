import { useRoutes } from "react-router-dom";
import './App.css';
import Customer from './pages/Customer';
import Home from './pages/Home';
import Layout from './pages/Layout';
import Order from './pages/Order';
import Product from './pages/Product';

function App() {

    const mainRoutes = {
        path: '/',
        element: <Layout />,
        children: [
            { path: '/', element: <Home /> },
            { path: 'customer', element: <Customer /> },
            { path: 'product', element: <Product /> },
            { path: 'order', element: <Order /> },
        ],
    };


    const routing = useRoutes([mainRoutes]);

    return <>{routing}</>;
}

export default App;
